<template>
  <v-app>
    <div class="card card-custom">
      <div class="card-body p-0">
        <div class="wizard wizard-2"
            id="kt_wizard_v2"
            data-wizard-state="step-first"
            data-wizard-clickable="true">
          <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
            <div class="row">
              <div class="col-md-6 col-lg-6 col-sm-12">
                <h4>
                  Take and exam day attendance for {{ course && course.course && course.course.title ? course.course.title : '-' }}
                </h4>
              </div>
            </div>
            <div class="row mb-5" style="background-color:whitesmoke">
              <div class="col-md-4">
                <b>Course: </b> {{ course && course.course && course.course.title ? course.course.title : null }} <br>
                <b>Total student: </b> {{result_students.length}}
              </div>

              <div class="col-md-4">
                <b>Exam title: </b> {{ exam ? exam.title : null }} <br>
                <b>Exam type: </b> {{ exam && exam.type ? exam.type.toUpperCase() : null }} <br>

              </div>

              <div class="col-md-4">
                <!--                <b>Theory full mark: </b> {{ course &&  course.theory_mark ? course.theory_mark : null }} <br>-->
                <!--                <b>Theory pass mark: </b> {{ course &&  course.theory_pass_mark ? course.theory_pass_mark : null }}   <br>-->
                <!--                <b>Practical full mark: </b> {{ course &&  course.practical_mark ? course.practical_mark : null }} <br>-->
                <!--                <b>Practical pass mark: </b> {{ course &&  course.practical_pass_mark ? course.practical_pass_mark : null }}   <br>-->
                <b>Program: </b> {{ (exam && exam.program) ? exam.program.title : null }} <br>
                <b>Semester/year: </b> {{ (exam && exam.grade) ? exam.grade.title : null }} <br>
              </div>
              <div class="col-10">
                <v-select v-model="class_room" :items="class_rooms" outlined dense label="Select classroom" item-text="title"
                          item-value="id"></v-select>
              </div>
              <div class="col-2">
                <v-btn depressed class="btn btn-primary text-white" @click="getAssignedStudents">Filter</v-btn>
                <!--                <v-btn depressed class="btn" @click=" resetStudentList">Reset</v-btn>-->
              </div>
            </div>
            <div class="row">
              <div class="col-8">
                <v-select v-model="class_room" :items="class_rooms" outlined dense label="Select classroom" item-text="title"
                          item-value="id"></v-select>
              </div>
              <div class="col-2">
                <v-btn depressed class="btn btn-primary text-white" @click="getAssignedStudents">Filter</v-btn>
                <!--                <v-btn depressed class="btn" @click=" resetStudentList">Reset</v-btn>-->
              </div>

            </div>
            <div class="row">
              <div class="col-12">
                <div class="" v-if="result_students && result_students.length>0">
                  <table class="table">
                    <thead>
                    <th>Student</th>
                    <th>Attendance</th>

                    </thead>
                    <tbody v-cloak>
                    <tr v-for="(student, index) of result_students" :key="index">
                      <td>
                        <a href="#!" @click.prevent="openSummaryPage(student)" class="mb-1">
                          <b>  {{ student.name }} </b><br/>
                        </a>


                        <span class="text-secondary">
                            <span class="text-secondary" v-if="student.midas_id">
                         <b> External ID:</b>  {{student.midas_id!=null  ? student.midas_id : 'NA'}}<br/>
                        </span>
                         <b> CRN No.:</b>  {{student.crn_no  ? student.crn_no : 'NA'}}<br/>
                         <b> Symbol No.:</b>  {{student.symbol_no  ? student.symbol_no : 'NA'}}<br/>
                        </span>

                        <span class="text-secondary" v-if="student.setting && student.setting.academic_year">
                          <b> Batch:</b>  {{student.setting.academic_year!=null  ? student.setting.academic_year : 'NA'}}<br/>
                        </span>
                      </td>
                      <!--                      <td>-->
                      <!--                        <span>{{ `FM : ${student.full_mark}` }}</span><br>-->
                      <!--                        <span>{{ `PM : ${student.pass_mark}` }}</span>-->
                      <!--                      </td>-->
                      <td>
                        <v-switch @change="changeStudentPresent"
                                  v-model="student.is_present"
                                  :label="student.is_present? 'Present' : 'Absent'">
                        </v-switch>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <v-alert v-else
                         type="error"

                         prominent
                         elevation="2"
                         colored-border
                         border="left"
                >
                  No students are assigned to this course
                </v-alert>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import ExamService from "@/core/services/exam/ExamService";
import ExamCourseService from "@/core/services/exam/course/ExamCourseService";
import AcademicClassService from "@/core/services/academic-class/AcademicClassService";
import StudentResultService from "@/core/services/user/student/result/StudentResultService";
const resultService = new StudentResultService();
const academicClassService = new AcademicClassService();
const examCourseService = new ExamCourseService;
const examService=new ExamService;
export default {
  data(){
    return{
      exam:null,
      course:null,
      is_loading:false,
      examLoading:false,
      assignedStudentLoading:false,
      result_students: [],
      selectedStudents: [],
      class_room: null,
      class_rooms: [],
    }
  },
  methods:{
    getExam() {
      this.examLoading=true;
      examService
          .getBySlug(this.slug).then(response => {
              this.exam = response.data.exams;
        this.getExamCourse();
        this.getAssignedStudents();
        this.getClassRooms();
      }).catch((err) => {
        // console.log(err)
      }).finally(() => {
        this.examLoading=false;
      });
    },
    getExamCourse() {
      examCourseService
          .show(this.exam.id,this.courseId)
          .then(response => {
            this.course = response.data.course;
          });
    },
    getClassRooms() {
      academicClassService
          .getByProgramLevel(this.exam.academic_year_id, this.exam.program_id, this.exam.grade_id)
          .then(response => {
            this.class_rooms = response.data
          });
    },
    getAssignedStudents() {
      this.assignedStudentLoading=true;
      let data = {}
      if (this.class_room)
        data = {academic_class_id: this.class_room}
      examService
          .getAssignedStudentsByCourse(this.exam.id, this.courseId, data)
          .then(response => {
              this.selectedStudents = response.data;
              this.result_students = this.selectedStudents.map((student) => {
                return {
                  student_id: student.student_id,
                  crn_no: student.crn_no,
                  name: student.name,
                  symbol_no: student.symbol_no,
                  midas_id: student.midas_id,
                  exam_course_id: this.courseId,
                  theory_obtained_marks: student.theory_obtained_marks,
                  practical_obtained_marks: student.practical_obtained_marks,
                  theory_grade: student.theory_grade,
                  practical_grade: student.practical_grade,
                  exam_id: this.exam.id,
                  total_grade: student.total_grade,
                  total_gpa: student.total_gpa,
                  is_absent: student.is_absent,
                  is_present: student.is_present,
                  is_pass: student.is_pass,
                  has_re_exam_results: student.has_re_exam_results,
                  full_mark: student.full_mark,
                  grade_point: student.grade_point,
                  pass_mark: student.pass_mark,
              }
              });
              this.assignedStudentLoading=false;
          })
          .catch((err) => {
            // console.log(err)
          })
          .finally(() => {
            this.assignedStudentLoading=false;
          });
    },
    saveStudentResultByCourse(){
      this.$confirm({
        message: `Do you want to save this ?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        callback: confirm => {
          if (confirm) {
            this.is_loading = true;
            resultService.saveStudentResultByCourse(this.exam.id, this.result_students).then(response => {
              this.$snotify.success("Record saved");
              this.is_loading = false;
              this.getAssignedStudents();
            }).catch(error => {

              // console.log(error)
            }).finally(() => {
              this.is_loading = false;
            })
          }
        }
      });
    },
    changeStudentPresent(){
      this.saveStudentResultByCourse();
    },
  },
  computed: {
    courseId() {
      return this.$route.params.courseId;
    },
    slug() {
      return this.$route.params.slug;
    }
  },
  mounted() {
    this.getExam();
  }
}
</script>